import { Link } from "react-router-dom";
import { UrlType } from "../Navigations";
import { generalObj } from "../constant/object_types";
import { FiChevronDown, FiXCircle } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";

const NavBar = ({ location, scrollTop }:{
    location : generalObj,
    scrollTop: number
}) => {
    const [hamBurger, setHamBurger] = useState<boolean>(false);

    return (
        <>
            <nav className={`${scrollTop > 200 ? 'nav__fixed' : ''}`}>
                <div className={`logo__img ${location.pathname === '/' ? 'active' : ''}`}>
                    <a href={UrlType.HOMEPAGE}>
                        <img src={require("../assets/img/logo.png")} alt="web logo" />
                    </a>
                </div>

                <ul className="navbar__contianer">
                    <li className={`dropdown ${location.hash === '#service' ? 'active' : ''}`}>
                        <Link to={`${UrlType.HOMEPAGE}#service`}>
                            <span style={{marginRight:'10px'}}>Services</span>
                            <FiChevronDown size={18} />
                        </Link>
                        <div>
                            <Link to={UrlType.PORTFOLIO}>
                                Our Portfolio
                            </Link>
                        </div>
                    </li>
                    <li className={`${location.hash === '#about' ? 'active' : ''}`}>
                        <Link to={`${UrlType.HOMEPAGE}#about`}>About Us</Link>
                    </li>
                    <li className={`${location.pathname === '/contact-us' ? 'active' : ''}`}>
                        <Link to={UrlType.CONTACTUS}>Contact</Link>
                    </li>
                    {!hamBurger && (
                        <li className={'hamMenu'}>
                            <button onClick={() => setHamBurger(true)}>
                                <FiMenu color={'#0093DE'} />
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
            <div className={`sideNav ${hamBurger ? 'active' : ''}`}>
                <ul className="navbar__contianer">
                    <li className={`${location.hash === '#service' ? 'active' : ''}`}>
                        <Link to={`${UrlType.HOMEPAGE}#service`}>
                            Services
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/portfolio' ? 'active' : ''}`}>
                        <Link to={UrlType.PORTFOLIO}>
                            Our Portfolio
                        </Link>
                    </li>
                    <li className={`${location.hash === '#about' ? 'active' : ''}`}>
                        <Link to={`${UrlType.HOMEPAGE}#about`}>About Us</Link>
                    </li>
                    <li className={`${location.pathname === '/contact-us' ? 'active' : ''}`}>
                        <Link to={UrlType.CONTACTUS}>Contact</Link>
                    </li>
                </ul>
                <button onClick={() => setHamBurger(false)}>
                    <FiXCircle />
                </button>
            </div>
        </>
    )
}

export default NavBar