import { About, Hero, Mission, MoreInfo, ParentContainer, Policy, Services, Vision } from "../component";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";
import { usePageContext } from "../context/PageContext";

const Landing = () => {

    const { hero, service } = usePageContext();
    const [modalLoading, setModalLoading] = useState<boolean>(true);
    
    useEffect(() => {
        if (hero.length===0 || service.length===0) return;

        setModalLoading(false);
    }, [hero, service]);

    useEffect(() => {
        AOS.init({ duration: 2000, once:true });
    }, []);

    return (
        <ParentContainer classNameCompnent={"landing"} preloader={modalLoading}>
            <>
                <Hero heroElement={hero} />
                <About />
                <Vision />
                <Mission />
                <Services serviceItem={service} />
                <Policy />
                <MoreInfo />
            </>
        </ParentContainer>
    )
}

export default Landing;