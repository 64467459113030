import { BASEURL } from "./object_types";
import axios from 'axios';

export const checkDeviceDesktop = () => {
  const expression = /(Mac|MacIntel|Win32|Linux x86_64|Linux armv81)/i;

  if (!expression.test(navigator.platform)) {
    return true;
  } else {
    return false;
  }
}

export const getPageInfo = async () => {
  var config = {
    method: 'GET',
    url:`${BASEURL}dashboard/landing/`,
    headers: {  
      'Content-Type': 'application/json'
    }
  };
  let result = await axios(config)
  .then((response) => {
    const resp = {
      status: true,
      data: response.data
    }
    return resp;
  })
  .catch((error) => {
    let resp;
    if (error.response.data.error) {
      resp = {
        status: false,
        message: error.response.data.message
      }
    }else {
      resp = {
        status: false,
        message: 'Error connecting to server, try again later',
      }
    }
    
    return resp;
  })
  return result;
}

export const sendEnquiry = async (name:string, phone:string, email:string, message:string) => {
  var config = {
    method: 'GET',
    url:`${BASEURL}dashboard/sendEnquiry/?fullname=${name}&phone=${phone}&email=${email}&message=${message}`,
    headers: {  
      'Content-Type': 'application/json'
    }
  };
  let result = await axios(config)
  .then((response) => {
    const resp = {
      status: true,
      data: response.data
    }
    return resp;
  })
  .catch((error) => {
    let resp;
    if (error.response.data.error) {
      resp = {
        status: false,
        message: error.response.data.message
      }
    }else {
      resp = {
        status: false,
        message: 'Error connecting to server, try again later',
      }
    }
    
    return resp;
  })
  return result;
}