const Mission = () => {
    return (
        <div className="mission">
            <div className="mission__description">
                <div data-aos="zoom-in-down">
                    <h4>Our Mission</h4>
                </div>
                <div data-aos="zoom-in-up" data-aos-delay="300">
                    <p>Leveraging on integrity and professionalism, our focus is to provide excellence and capacity in our services to the clients.</p>
                </div>
            </div>
            <div className="mission__img" data-aos="zoom-in-left" data-aos-delay="300" style={{backgroundImage:`url(${require('../assets/img/cargo.png')})`}}>
            </div>
            <div className="skew__rectangle_container">
                <div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Mission; 