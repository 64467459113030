import { generalObj } from "../constant/object_types";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UrlType } from "../Navigations";
import { checkDeviceDesktop } from "../constant/action";

const Services = ({ serviceItem }:{
    serviceItem : generalObj[];
}) => {

    const responsive:generalObj = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <div className="service" id="service">
            <div className="service__description">
                <div data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom">
                    <h4>Our Services</h4>
                </div>
                <div data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="500">
                    <p>With our solid partnerships and relationships with the most trusted names in the industry, we are always ready to provide the most efficient, compliant, reliable, safe & budget-conscious equipment and services to our esteemed clients within record time.</p>
                </div>
            </div>
            <div className="slider_carousel" data-aos="zoom-in" data-aos-delay="300">
                
                <div className="left-flag"></div>
                <div className="right-flag"></div>
                <Carousel swipeable={true} draggable={true} responsive={responsive} autoPlay={checkDeviceDesktop() ? false : true} infinite={true} autoPlaySpeed={2000} removeArrowOnDeviceType={["tablet", "mobile"]}>
                    {serviceItem.map((item:generalObj, index:number) => (
                        <div className="slider_carousel__item" key={index}>
                            <div>
                                <img src={item.image} alt={item.title} />
                                <div className="overlay">
                                    <p>{item.title}</p>
                                    <p className="description">{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className="services_footer">
                <p>Learn more about our works and projects </p>
                <div data-aos="zoom-in-up" data-aos-delay="300">
                    <a href={UrlType.PORTFOLIO}>View Portfolio</a>
                </div>
            </div>
        </div>
    )
}

export default Services; 