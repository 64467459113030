import { useLocation } from "react-router-dom";
import FootBar from "./FootBar";
import NavBar from "./NavBar"
import { useEffect, useState } from "react";
import ModalLoader from "./ModalLoader";

const ParentContainer = ({ children, preloader, classNameCompnent }:{
    children: JSX.Element,
    preloader: boolean,
    classNameCompnent ?: string,
}) => {
    const location = useLocation();
    const [openLoader, setOpenLoader] = useState<boolean>(true);
    // const [getScrollHeight, setScrollHeight] = useState<number>(0);

    // useEffect(() => {
    //     document.onscroll = (evt) => {
    //         setScrollHeight(document.documentElement.scrollTop);
    //     }
    // }, []);
    useEffect(() => {
        if (preloader) return;
        let modalTimeout = setTimeout(() => {
            setOpenLoader(false);
        }, 3000);

        return () => clearTimeout(modalTimeout);
    }, [preloader]);

    useEffect(() => {
        if (!location || location.hash === '') return;
        let target = document.querySelector(location.hash);
        if (target) {
            target.scrollIntoView({behavior: "smooth"});
        }
    });

    return (
        <>
            <NavBar location={location} scrollTop={0} />
            <div className={`mainBody ${classNameCompnent}`}>
                <ModalLoader visible={openLoader} />
                {children}
            </div>
            <FootBar />
        </>
    )
}

export default ParentContainer;