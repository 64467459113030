import { useState, useEffect } from "react";
import PageContext from "./PageContext";
import { generalObj } from "../constant/object_types";
import { getPageInfo } from "../constant/action";

const PageProvider = ({ children }:{
    children : JSX.Element
}) => {
    const [hero, setHero] = useState<generalObj[]>([]);
    const [service, setService] = useState<generalObj[]>([]);
    const [portfolio, setPortfolio] = useState<generalObj[]>([]);

    useEffect(() => {
        (async () => {
            let result:generalObj = await getPageInfo();
            if (result.status) {
                setHero(result.data.hero);
                setService(result.data.service);
                setPortfolio(result.data.portfolio);
            }else {
                alert(result.message);
            }
        })();
    }, []);

    return (
        <PageContext.Provider value={{ hero, service, portfolio }}>
            {children}
        </PageContext.Provider>
    );
};

export default PageProvider;