import { FacebookSvg, InstagramSvg, TwitterSvg } from "../IconSvg";

const FootBar = () => {
    return (
        <footer>
            <div>
                <div className={`logo__img`}>
                    <img src={require("../assets/img/logo_transparent.png")} alt="web logo" />
                </div>
                <div className="contact__info">
                    <div><strong>Office Address:</strong> <a href="https://maps.app.goo.gl/3Tuo7qKJueqnhg9p7">6 Christopher Ugbaja Bera Estate, Chervon Drive, Lekki, Lagos</a></div>

                    <div>
                        <div><strong>Email:</strong> <a href="mailto:info@petroprofs.com">info@petroprofs.com</a></div>
                        <div style={{marginTop:'3px'}}><strong>Contact:</strong> <a href="tel:+2348140144428">0814 014 4428</a></div>
                    </div>
                    
                    <div className="social_icons">
                        <span><FacebookSvg /></span>
                        <span><InstagramSvg /></span>
                        <span><TwitterSvg /></span>
                    </div>
                </div>
            </div>
            <p>© 2023 Rabit Consulting LLC. All Rights Reserved.</p>
        </footer>
    )
}

export default FootBar