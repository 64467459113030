import { useState } from "react";
import { ParentContainer } from "../component";
import { generalObj } from "../constant/object_types";
import { sendEnquiry } from "../constant/action";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        'fullname': '',
        'phone_number': '',
        'email': '',
        'message': ''
    });

    const onTextChange = (evt:generalObj) => {
        setFormData((m) => ({...m, [evt.target.name]:evt.target.value}));
    }

    const onSubmit = async (e:generalObj) => {
        e.preventDefault();
        let result:generalObj = await sendEnquiry(formData.fullname, formData.phone_number, formData.email, formData.message);

        if (result.status){
            alert(result.data.message);
            setFormData({
                'fullname': '',
                'phone_number': '',
                'email': '',
                'message': ''
            });
        }else {
            alert(result.message);
        }
    }

    return (
        <ParentContainer classNameCompnent={"contact"} preloader={false}>
            <>
                <div className="contact__us__img">
                </div>
                <div className="contactFormContainer">
                    <div className="contactHeader">
                        <h2>Get In Touch With Us</h2>
                        <p>Investment, Business or Enquiries? Let’s hear from you!</p>
                    </div>
                    <div className="contactForm">
                        <form onSubmit={(e) => onSubmit(e)}>
                            <div className="mainForm">
                                <div className="w-100">
                                    <input type="text" placeholder="Full Name" name="fullname" value={formData.fullname} onChange={(evt) => onTextChange(evt)} required  />
                                </div>
                                <div className="w-50">
                                    <input type="phone" placeholder="Phone" name="phone_number" value={formData.phone_number} onChange={(evt) => onTextChange(evt)} required  />
                                </div>
                                <div className="w-50">
                                    <input type="email" placeholder="Email" name="email" value={formData.email} onChange={(evt) => onTextChange(evt)} required  />
                                </div>
                                <div className="w-100">
                                    <textarea placeholder="Message" name="message" value={formData.message} onChange={(evt) => onTextChange(evt)} required />
                                </div>
                            </div>
                            <div className="submitBtn">
                                <button type={'submit'}>Send Mail</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        </ParentContainer>
    )
}

export default ContactUs;