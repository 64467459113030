import { useEffect, useState } from "react";
import { ParentContainer } from "../component";
import { generalObj } from "../constant/object_types";
import { usePageContext } from "../context/PageContext";

const Portfolio = () => {
    const { portfolio } = usePageContext();
    const [modalLoading, setModalLoading] = useState<boolean>(true);
    
    useEffect(() => {
        if (portfolio.length===0) return;

        setModalLoading(false);
    }, [portfolio]);
    
    return (
        <ParentContainer classNameCompnent={"portfolio"} preloader={modalLoading}>
            <>
                <div className="banner">
                    <div></div>
                    <h3>Our Portfolio</h3>
                </div>
                <div className="portfolio__container">
                    {portfolio.map((item:generalObj) => (
                        <div className="portfolio__item" key={item.id}>
                            <div className="port__img">
                                <img src={item.image} alt={item.title} />
                            </div>
                            <h4>{item.title}</h4>
                            <div className="port__divider"></div>
                            <h6>{item.work_type}</h6>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
            </>
        </ParentContainer>
    )
}

export default Portfolio;