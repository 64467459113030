import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.css';
import { ContactUs, Landing, Portfolio } from './pages';
import PageProvider from './context/PageProvider';

const App = () => {
  return (
    <PageProvider>
      <Router>
        <Routes>
          <Route 
            caseSensitive
            path={"/"}
            element={<Landing />}
          />
          <Route 
            caseSensitive
            path={"/contact-us"}
            element={<ContactUs />}
          />
          <Route 
            caseSensitive
            path={"/portfolio"}
            element={<Portfolio />}
          />
        </Routes>
      </Router>
    </PageProvider>
  );
}

export default App;
