import { createContext, useContext } from "react";
import { generalObj } from "../constant/object_types";

interface PageContextInterface {
    hero: generalObj[];
    service: generalObj[];
    portfolio: generalObj[];
  }
  

const PageContext = createContext<PageContextInterface | undefined>(undefined);

export const usePageContext = () => {
    const context = useContext(PageContext);
    if (context === undefined) {
      throw new Error("usePageContext must be used within a PageProvider");
    }
    return context;
};
  
export default PageContext;