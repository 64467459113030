import { CapacitySvg, EfficencySvg, ExcellentSvg, TeamWorkSvg } from "../IconSvg";
import { generalObj } from "../constant/object_types";

const Policy = () => {
    const policyItem:generalObj[] = [
        {
            id:1,
            title: 'Excellence',
            icon: <ExcellentSvg />
        },
        {
            id:2,
            title: 'Teamwork',
            icon: <TeamWorkSvg />
        },
        {
            id:3,
            title: 'Efficiency',
            icon: <EfficencySvg />
        },
        {
            id:4,
            title: 'Capacity',
            icon: <CapacitySvg />
        }
    ]
    return (
        <div className="policy">
            <div className="policy__description">
                <div data-aos="zoom-in-up">
                    <h4>Quality Policy</h4>
                </div>
                <div data-aos="zoom-in" data-aos-delay="100">
                    <p>Safety is paramount in our operations and our personnel are the greatest resources. Our value policy is our operational framework which puts us beyond traditional limits through quality assurance/ quality checking, hence we thrive through:</p>
                </div>
            </div>
            <div className="policy_item__container">
                {policyItem.map((item:generalObj, index:number)=>(
                    <div className="policy_item" key={item.id} data-aos="zoom-in" data-aos-delay={`${150 * ((index + 1)*2)}`}>
                        <div>
                            {item.icon}
                        </div>
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Policy;