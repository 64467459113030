import { UrlType } from "../Navigations";

const MoreInfo = () => {
    return (
        <div className="info">
            <div className="info__description">
                <h4>Need more information?</h4>
                <p>Contact us through the most convenient channel and we can help with any information you may need.</p>
                <div data-aos="zoom-in-up">
                    <a href={UrlType.CONTACTUS}>Contact Us</a>
                </div>
            </div>
        </div>
    )
}

export default MoreInfo;