const About = () => {
    return (
        <div className="about" id="about">
            <div className="about__description">
                <div data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom">
                    <h4>About Us</h4>
                </div>
                <div data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="200">
                    <p>Petroprofs Oil and Gas Services Ltd (POGS) is an indigenous oil and gas servicing company with focus on the upstream and midstream sectors. The company was incorporated under the Companies and Allied Matter Acts 1990 by the Corporate Affairs Commission of Nigeria on the 7th of January 2019 with headquarters in Lagos, Nigeria.</p>
                </div>
                <div data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="500">
                    <p>The company was founded by Subject Matter Experts with vast experience to support asset owners in oil and gas development, production and export operations. POGS is committed in setting the pace across land, swamp and offshore terrains.</p>
                </div>
            </div>
            <div className="barrell">
                <div>
                    <div className="barrel__circle" data-aos="zoom-in">
                    </div>
                    <div className="barrel" data-aos="zoom-in-left" data-aos-anchor-placement="top-bottom" data-aos-offset="300">
                        <img src={require('../assets/img/barrel.png')} alt='barrel' />
                    </div>
                    <div className="barrel__mdcircle" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="2000" data-aos-anchor-placement="top-bottom"></div>
                    <div className="barrel__smcircle" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="400" data-aos-anchor-placement="top-bottom"></div>
                </div>
            </div>
        </div>
    )
}

export default About;