import { Carousel } from "react-responsive-carousel";
import { generalObj } from "../constant/object_types";

const Hero = ({ heroElement }:{
    heroElement : generalObj[]
}) => {
    // const heroElement = [
    //     {
    //         id: 1,
    //         image: require('../assets/img/hero1.png'),
    //         title: 'indigenous oil & gas',
    //         description: 'Petroprofs Oil and Gas Services Ltd (POGS) is an indigenous oil and gas servicing company with focus on the upstream and midstream sectors'
    //     },
    //     // {
    //     //     id: 2,
    //     //     image: require('../assets/img/hero1.png'),
    //     //     //image: require('../assets/img/mpl.gif'),
    //     //     title: 'Lorem ipsum dolor',
    //     //     description: 'Lorem ipsum dolor sit amet consectetur. Viverra tempus sed odio in porta. Cras vestibulum rhoncus urna et nunc. Tempor'
    //     // }
    // ]
    return (
        <section className="hero">
            <Carousel showArrows={false} infiniteLoop={true} interval={10000} autoPlay={true} showThumbs={false} showStatus={false} showIndicators={false}>
                {heroElement.map((item:generalObj, index:number) => (
                    <div className="carousel__item" key={index}>
                        <img src={item.image} alt={item.name} />
                        <div data-aos="zoom-in-left">
                            <div className="flag" ></div>
                            <div className="heroText">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
            <div className="skew__rectangle_container">
                <div>
                </div>
                <div></div>
            </div>
        </section>
    )
}

export default Hero;