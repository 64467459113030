const ModalLoader = ({ visible }:{
    visible : boolean
}) => {
    return (
        <section className={`preloader ${visible ? "active" : ""}`}>
            <div className="img__shadow__cont">
                <div className="img">
                    <img src={require('../assets/img/logo_short.png')} alt="preloader" />
                </div>
                <div className="img__shadow"></div>
            </div>
        </section>
    )
}

export default ModalLoader;