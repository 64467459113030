const Vision = () => {
    return (
        <div className="vision">
            <div className="binocular">
                <div>
                    <div className="binoculars__circle" data-aos="zoom-in">
                    </div>
                    <div className="binoculars__smcircle" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="2000" data-aos-anchor-placement="top-bottom"></div>
                    <div className="binoculars" data-aos="zoom-in-right" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                        <img src={require('../assets/img/binoculars.png')} alt='binoculars' />
                    </div>
                </div>
            </div>
            <div className="vision__description">
                <div data-aos="zoom-in">
                    <h4>Our Vision</h4>
                </div>
                <div data-aos="fade-left" data-aos-delay="200">
                    <p>At Petroprofs, we are challenging the status quo by providing excellent services to become the world-class integrated solutions provider to the Oil & Gas sector including the marine industry.</p>
                </div>
            </div>
            
        </div>
    )
}

export default Vision; 